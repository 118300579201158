import axios from "axios";
import { MP_API_URL } from "../config/AppConst";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { toRaw } from "vue";

const apiClient = axios.create({
  baseURL: MP_API_URL,
});

function setHeader() {
  if (JwtService.getToken()) {
    apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
}

async function handleApiCall(apiCallFunction) {
  try {
    const response = await apiCallFunction();
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await router.push({ name: "sign-in" });
    } else {
      throw error;
    }
  }
}

function convertToFormData(data) {
  const formData = new FormData();
  const rawData = toRaw(data); // Ensure the data is a plain object

  for (const key in rawData) {
    if (Object.prototype.hasOwnProperty.call(rawData, key)) {
      const value = rawData[key];
      if (value instanceof File) {
        formData.append(key, value, value.name); // Include file name
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
}

export async function post(endpoint, data, isMultipart = false) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data before convert", data);

  if (isMultipart) {
    data = convertToFormData(data);
    apiClient.defaults.headers.common["Content-Type"] = "multipart/form-data";

    console.log("data after convert (FormData):");
    for (const [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }
  } else {
    delete apiClient.defaults.headers.common["Content-Type"];
  }

  return handleApiCall(() => apiClient.post(endpoint, data));
}

export async function get(endpoint) {
  setHeader();
  console.log("endpoint", endpoint);
  return handleApiCall(() => apiClient.get(endpoint));
}
export async function del(endpoint) {
  setHeader();
  console.log("endpoint", endpoint);
  return handleApiCall(() => apiClient.delete(endpoint));
}

export async function put(endpoint, data, isMultipart = false) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data", data);
  if (isMultipart) {
    data = convertToFormData(data);
    apiClient.defaults.headers.common["Content-Type"] = "multipart/form-data";

    console.log("data after convert (FormData):");
    for (const [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }
  } else {
    delete apiClient.defaults.headers.common["Content-Type"];
  }
  return handleApiCall(() => apiClient.put(endpoint, data));
}

export async function patch(endpoint, data) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data", data);
  return handleApiCall(() => apiClient.patch(endpoint, data));
}
